// Generated by ReScript, PLEASE EDIT WITH CARE


var enFallback = 'en';

var jaFallback = 'ja';

export {
  enFallback ,
  jaFallback ,
}
/* No side effect */
